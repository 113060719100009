// import DigestView from "../components/DigestView";
// import ReplicaView from "../components/ReplicaView";
// import GalleryView from "../components/GalleryView";
// import AboutPage from "../components/AboutPage";
// import AdvertisePage from "../components/AdvertisePage";
// import TermsAndConditionsPage from "../components/TermsAndConditionsPage";
// import PrivacyPolicyPage from "../components/PrivacyPolicyPage";
// import Renew from "../components/LoginWizard/steps/Renew";
// import ShareArticle from "../components/ShareArticle";
// import Overview from "../components/Overview";
import "driver.js/dist/driver.min.css";
// import MyAccount from "../components/MyAccount";
import "intro.js/introjs.css";
// import AdTesting from "../components/AdTesting";
// import ArchiveCopy from "../components/ArchiveCopy";
// import CookiesChoice from "../components/CookiesChoice";
// import FaqPage from "../components/FaqPage";
// import AboutEpaper from "../components/AboutEpaper";
// import LandingPageTest from "../components/LandingPageTest";
// import Bookmarks from "../components/Bookmarks";
// import ShareDigitalArticle from "../components/ShareDigitalArticle";
// import AdvancedSearch from "../components/AdvancedSearch";
// import AdSearch from "../components/Adsearch";
import loadable from "@loadable/component";

const LandingPageTest = loadable(() => import("../components/LandingPageTest"));
const ReplicaView = loadable(() => import("../components/ReplicaView"));
const Bookmarks = loadable(() => import("../components/Bookmarks"));
const ArchiveCopy = loadable(() => import("../components/ArchiveCopy"));
const FaqPage = loadable(() => import("../components/FaqPage"));
const MyAccount = loadable(() => import("../components/MyAccount"));

const AboutEpaper = loadable(() => import("../components/AboutEpaper"));
const AboutPage = loadable(() => import("../components/AboutPage"));
const AdSearch = loadable(() => import("../components/Adsearch"));

const PrivacyPolicyPage = loadable(() =>
  import("../components/PrivacyPolicyPage")
);

const TermsAndConditionsPage = loadable(() =>
  import("../components/TermsAndConditionsPage")
);

const AdvertisePage = loadable(() => import("../components/AdvertisePage"));

const ShareDigitalArticle = loadable(() =>
  import("../components/ShareDigitalArticle")
);

const AdvancedSearch = loadable(() => import("../components/AdvancedSearch"));
const ShareArticle = loadable(() => import("../components/ShareArticle"));
const CookiesChoice = loadable(() => import("../components/CookiesChoice"));

const authProtectedRoutes = [
  { path: "/bookmarks", exact: true, component: Bookmarks },
  // { path: "/digest-view", exact: true, component: DigestView },
  // { path: "/gallery-view", exact: true, component: GalleryView },
  { path: "/my-account", exact: true, component: MyAccount },
  { path: "/archives", exact: true, component: ArchiveCopy },
  { path: "/ad-manifest", exact: true, component: AdSearch },
  { path: "/:publication/:edition", exact: true, component: ReplicaView },
];
const publicRoutes = [
  // { path: "/overview", exact: true, component: Overview },
  { path: "/about", exact: true, component: AboutPage },
  { path: "/faqpage", exact: true, component: FaqPage },
  { path: "/about-epaper", exact: true, component: AboutEpaper },

  { path: "/check-users-cookie", exact: true, component: CookiesChoice },

  { path: "/advertise", exact: true, component: AdvertisePage },
  { path: "/terms", exact: true, component: TermsAndConditionsPage },
  { path: "/privacy", exact: true, component: PrivacyPolicyPage },
  // { path: "/renew-subscription", exact: true, component: Renew },
  { path: "/home", exact: true, component: LandingPageTest },
  { path: "/advanced-search", exact: true, component: AdvancedSearch },

  {
    path: "/digital-article-share",
    exact: true,
    component: ShareDigitalArticle,
  },
  { path: "/article-share", exact: true, component: ShareArticle },
  // { path: "/ads", exact: true, component: AdTesting },
];
export { authProtectedRoutes, publicRoutes };
